import React from 'react'

function Success() {
  return (
    <div>
      Login Success!
    </div>
  )
}

export default Success
